import { styled } from 'stitches.config';
import useMedia from 'Shared/Hooks/useMedia';
import Price from 'Atoms/Price/Price';
import Modal from 'Organisms/Modal/Modal';
import PricePerMonth from 'Atoms/Price/PricePerMonth';
import Button from 'Atoms/Buttons/Button';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import { ModalTitle } from '../Order/MyOptions';
import ProductBaseModel from 'Models/Pages/Base/ProductBaseModel.interface';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import Attributes from 'Molecules/Attributes/Attributes';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ProductPackage from 'Pages/ProductPage/ProductPackage';
import { isEmpty } from 'Shared/Common/Helpers';

type PackageModalTypes = {
  showModal: boolean;
  setShowModal: (set: boolean) => void;
  product?: ProductBaseModel;
  onAddItem: (code: string) => void;
};

const PackageModal = ({
  showModal,
  setShowModal,
  product,
  onAddItem,
}: PackageModalTypes) => {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const { productLabels } = useTranslationData();

  return (
    <Modal showModal={showModal} toggle={() => setShowModal(!showModal)}>
      {!product ? (
        <> </>
      ) : (
        <>
          <PackageModalContent>
            <TopMargin />
            <Attributes
              attributeList={[
                { value: product.parentCategoryName },
                { value: product.brand },
              ]}
            />
            <ModalTitle>{product.displayName}</ModalTitle>
            {!isEmpty(product.usp) && (
              <VariantUSP>
                {product.usp.map((uspText: string, index: number) => (
                  <BulletPoint
                    key={index}
                    text={uspText}
                    backgroundColor="secondaryDark"
                  />
                ))}
              </VariantUSP>
            )}
            <Description>{product.extendedDescription}</Description>
            <PackageCardWrapper>
              <ProductPackage
                packageItems={product.packageItems}
                isPackage={true}
                packageIncludes={productLabels.packageIncludes}
              />
            </PackageCardWrapper>
          </PackageModalContent>
          <VariantPrice>
            <div>
              <Price
                friendlyWasPrice={
                  product.variationCollection[0].wasPrice?.priceRoundedAsString
                }
                friendlyNowPrice={
                  product.variationCollection[0].nowPrice?.priceRoundedAsString
                }
                wasPrice={product.variationCollection[0].wasPrice?.price}
                nowPrice={product.variationCollection[0].nowPrice?.price}
                size={isDesktop ? 'lg' : 'sm'}
              />
              <MonthlyPrice>
                <PricePerMonth
                  friendlyMonthlyPrice={
                    product.variationCollection[0].paymentMonthlyCost
                      ?.priceRoundedAsString
                  }
                />
              </MonthlyPrice>
            </div>
            <Button
              onClick={() => onAddItem(product.variationCollection[0].code)}
            >
              {productLabels.add}
            </Button>
          </VariantPrice>
        </>
      )}
    </Modal>
  );
};

const PackageModalContent = styled('div', {
  height: '80vh',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

const TopMargin = styled('div', {
  mt: 20,
});

const VariantPrice = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
  backgroundColor: '$primary4',
  bottom: 0,
  left: 0,
  right: 0,
  px: 4,
  py: 6,
  position: 'absolute',
  '@mediaMinLarge': {
    py: 8,
  },
});

const MonthlyPrice = styled('div', {
  mt: 2,
  fs: 6,
  display: 'flex',
  gap: 8,
  lineHeight: '$lh125',
  fontFamily: '$fontSecondary400',
  color: '$productDescriptionTextPrimary',
});

const VariantUSP = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  mb: 8,
  mt: 6,
});

const Description = styled('div', {
  fontFamily: '$fontSecondary400',
  color: '$textPrimary',
  letterSpacing: 0,
  lineHeight: '$lh15',
  fs: 8,
  mb: 6,
});

const PackageCardWrapper = styled('div', {
  '&:not(:last-child)': {
    py: 4,
    borderBottomWidth: '1px',
    borderBottomColor: '$primary6',
  },
  pointerEvents: 'none', // Disable navigation on the children components
  display: 'flex',
  postion: 'relative',
  flexDirection: 'column',
  rowGap: '16px',
  zIndex: '1',
});

export default PackageModal;
