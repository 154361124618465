import { BackIcon } from 'Atoms/Icons';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

type CloseButtonBaseType = {
  onClick?: () => void;
  css?: CSS;
  size: 'xs' | 's' | 'm' | 'l';
  bgColor: 'primary' | 'secondary' | 'primaryLight';
};

function BackButton({
  onClick = () => {},
  css,
  size,
  bgColor = 'primary',
}: CloseButtonBaseType) {
  const iconColor =
    bgColor === ('primary' || 'primaryLight') ? 'secondary' : 'secondaryDark';
  return (
    <BackBtn css={css} onClick={onClick} size={size}>
      <CircleBackground bgColor={bgColor}>
        <BackIcon color={iconColor} />
      </CircleBackground>
    </BackBtn>
  );
}
export default BackButton;

const BackBtn = styled('button', {
  variants: {
    size: {
      xs: {
        wh: 2,
      },
      s: {
        wh: 4,
      },
      m: {
        wh: 6,
      },
      l: {
        wh: 8,
      },
    },
  },
});

const CircleBackground = styled('span', {
  wh: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  variants: {
    bgColor: {
      primary: {
        backgroundColor: '$primary4',
        '&:hover': {
          backgroundColor: '$primary3',
        },
      },
      primaryLight: {
        backgroundColor: '$primary3',
        '&:hover': {
          backgroundColor: '$primary2',
        },
      },
      secondary: {
        backgroundColor: '$pureWhite',
        '&:hover': {
          backgroundColor: '$secondary1',
        },
      },
    },
  },
});
