import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { styled } from 'stitches.config';
import MCCVariations from '../MCCVariations';

type PropTypes = {
  variationCollection: VariationModel[];
  cartName: string;
};

const ColorTab = ({ variationCollection, cartName }: PropTypes) => {
  return (
    <ColorTabContainer>
      <MCCVariations
        variationCollection={variationCollection}
        cartName={cartName}
      />
    </ColorTabContainer>
  );
};

const ColorTabContainer = styled('div', {});

export default ColorTab;
