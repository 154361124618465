import ProductImage from 'Atoms/Image/ProductImage';
import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import { styled } from 'stitches.config';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { useVariantData as useMCCVariantData } from '../../Shared/Providers/MCCVariantProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { Indicator, Stock } from 'Atoms/StockViewer/StockViewer';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { Variant } from 'Enums/Variant.enum';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

type PropTypes = {
  variationCollection: VariationModel[];
  cartName: string;
};

function MCCVariations({ variationCollection, cartName }: PropTypes) {
  const { productLabels } = useTranslationData();
  const {
    missingImage,
    staticPages: { motorcycleConfiguratorPage },
  } = useAppSettingsData();
  const { variantDispatch, state } = useMCCVariantData();
  const kexNavigate = useKexNavigate();

  function changeVariant(code: string) {
    const variant = variationCollection.find(
      (variant) => variant.code === code
    );
    variantDispatch({
      type: Variant.SET_VARIANT,
      selectedVariant: variant,
    });
    kexNavigate(
      `${motorcycleConfiguratorPage}?productcode=${variant?.productCode}&variantcode=${variant?.code}`
    );
  }

  return (
    <VariationsContainer>
      {variationCollection.map((variant) => {
        return (
          <SingleOptionCard
            key={variant.code}
            content={
              <SingleOptionContent>
                <SingleOptionCardText>
                  {variant.color.name}
                  {variant.stock.stockAvailableAtVendor ? (
                    <Tooltip text={productLabels.extendedDeliveryTime} />
                  ) : (
                    <Stock>
                      <Indicator inStock={variant.stock?.inStock} />
                      <Paragraph size={'xs'}>
                        {variant.stock?.inStock ? (
                          <>
                            {variant.stock?.stockQuantity}{' '}
                            {productLabels.inStock}
                          </>
                        ) : (
                          productLabels.notInStock
                        )}
                      </Paragraph>
                    </Stock>
                  )}
                </SingleOptionCardText>
              </SingleOptionContent>
            }
            image={
              <ImageContainer>
                <ProductImage
                  src={
                    variant.image
                      ? `${variant.image?.src}?preset=small-product-card-desktop`
                      : `${missingImage.src}?preset=small-product-card-desktop`
                  }
                  alt=""
                />
              </ImageContainer>
            }
            onClick={() => {
              changeVariant(variant.code);
            }}
            isActive={state?.selectedVariant?.code === variant.code}
          />
        );
      })}
    </VariationsContainer>
  );
}

const VariationsContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const SingleOptionContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '$primary4',
  flexGrow: 1,
  px: 6,
});

const SingleOptionCardText = styled('span', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  columnGap: 16,
  textAlign: 'left',
  color: '$secondary2',
  width: '100%',
  fs: 8,
  lineHeight: '$lh15',
  '@mediaMinLarge': {
    fs: 9,
    lineHeight: '$lh155',
  },
});

const ImageContainer = styled('div', { flexShrink: 0, width: 80, height: 64 });
export default MCCVariations;
