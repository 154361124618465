import ProductBaseModel from 'Models/Pages/Base/ProductBaseModel.interface';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import OptionCardCheckbox from 'Organisms/SingleOptionCard/OptionCardCheckbox';
import PackageModal from '../ConfiguratorModal/PackageModal';
import { useState } from 'react';
import { styled } from 'stitches.config';
import {
  GetMotorcycleConfiguratorCart,
  RemoveFromMotorcycleConfiguratorCart,
  UpdateMotorcycleConfiguratorCart,
} from 'Shared/MotorcycleConfiguratorCart/MotorcycleConfiguratorCart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

type PackageTabTypes = {
  data: MotorcycleConfiguratorPageModel;
};

const PackageTab = ({ data }: PackageTabTypes) => {
  const { languageRoute } = useAppSettingsData();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<ProductBaseModel>();

  const onAddItem = (code: string) => {
    const quantity = 1;

    UpdateMotorcycleConfiguratorCart(
      code,
      quantity,
      languageRoute,
      data?.cartName
    );
    setShowModal(false);
  };

  const configuratorCart = GetMotorcycleConfiguratorCart(
    languageRoute,
    data?.cartName
  );

  return (
    <>
      <PackageModal
        showModal={showModal}
        setShowModal={setShowModal}
        product={currentProduct}
        onAddItem={onAddItem}
      />
      <PackageTabContainer>
        {data.packages.map(
          (productPackage: ProductBaseModel, index: number) => {
            const alreadyAdded = configuratorCart.cart?.lineItems?.find(
              (item) => item.code === productPackage.variationCollection[0].code
            );

            if (alreadyAdded) {
              return (
                <div key={index}>
                  <OptionCardCheckbox
                    onClickCheckbox={() => {
                      RemoveFromMotorcycleConfiguratorCart(
                        productPackage.variationCollection[0].code,
                        languageRoute,
                        data.cartName
                      );
                    }}
                    isActive={true}
                    title={productPackage.displayName}
                    price={
                      productPackage.variationCollection[0].nowPrice
                        .priceAsString
                    }
                    onClickInfo={() => {
                      setCurrentProduct(productPackage);
                      setShowModal(true);
                    }}
                    text={productPackage.extendedDescription}
                  />
                </div>
              );
            }

            return (
              <div key={index}>
                <OptionCardCheckbox
                  onClickCheckbox={() => {
                    onAddItem(productPackage.variationCollection[0].code);
                  }}
                  isActive={false}
                  title={productPackage.displayName}
                  price={
                    productPackage.variationCollection[0].nowPrice.priceAsString
                  }
                  onClickInfo={() => {
                    setCurrentProduct(productPackage);
                    setShowModal(true);
                  }}
                  text={productPackage.extendedDescription}
                />
              </div>
            );
          }
        )}
      </PackageTabContainer>
    </>
  );
};

const PackageTabContainer = styled('div', {});

export default PackageTab;
