import { useState, useEffect, useMemo } from 'react';
import { styled } from 'stitches.config';

import { useVariantData } from '../../Shared/Providers/MCCVariantProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

import { Variant } from 'Enums/Variant.enum';
import { isEmpty } from 'Shared/Common/Helpers';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';

import PackageTab from './Tab/PackageTab';
import ColorTab from './Tab/ColorTab';
import AccessoryTab from './Tab/AccessoryTab';
import TabButton from 'Atoms/Buttons/TabButton';
import TabButtonGroup from 'Molecules/ButtonGroups/TabButtonGroup';

import { GetMotorcycleConfiguratorCart } from 'Shared/MotorcycleConfiguratorCart/MotorcycleConfiguratorCart';

import { SizeConstants } from 'Theme/Settings/sizes';
import { parseQueryParameters } from 'Shared/Common/UrlParser';

const tabButtonGroupHeight = 86;

export const calcHeightToBarDesktop =
  SizeConstants.desktopHeaderHeight +
  SizeConstants.desktopConfiguratorBarHeight;

export const calcHeightToBarMobile =
  SizeConstants.mobileHeaderHeight + SizeConstants.mobileConfiguratorBarHeight;

type PropTypes = {
  data: MotorcycleConfiguratorPageModel;
};

const PACKAGE = 'package';
const COLOR = 'color';
const ACCESSORIES = 'accessories';

function ConfigurationArea({ data }: PropTypes) {
  const hasMCPackages = !isEmpty(data.packages);
  const hasMCAccessories = !isEmpty(data.accessories);

  const initialTab = useMemo(() => {
    const hasOneColor = data.variationCollection.length === 1;

    if (hasMCPackages) {
      return PACKAGE;
    } else if (hasOneColor && hasMCAccessories) {
      return ACCESSORIES;
    } else {
      return COLOR;
    }
  }, [hasMCPackages, data.variationCollection, hasMCAccessories]);

  const [currentTab, setCurrentTab] = useState<string>(initialTab);
  const { cartName, preselectedVariantCode } =
    useCurrentPage<MotorcycleConfiguratorPageModel>();
  const { languageRoute } = useAppSettingsData();
  const configuratorCart = GetMotorcycleConfiguratorCart(
    languageRoute,
    cartName
  );

  const {
    productLabels: {
      color: colorLabel,
      accessories: accessoriesLabel,
      package: packageLabel,
    },
  } = useTranslationData();

  const { variantDispatch } = useVariantData();

  useEffect(() => {
    if (isEmpty(configuratorCart.cart)) return;

    const defaultVariantCode =
      parseQueryParameters().get('variantcode') || preselectedVariantCode;

    // this data is fetched from current page
    const variant = data.variationCollection.find(
      (variant) => variant.code === defaultVariantCode
    );

    if (variant) {
      variantDispatch({
        type: Variant.SET_INITIAL,
        selectedVariant: variant,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuratorCart.cart]);

  const renderTabContent = () => {
    switch (currentTab) {
      case PACKAGE:
        return <PackageTab data={data} />;
      case COLOR:
        return (
          <ColorTab
            variationCollection={data.variationCollection}
            cartName={data.cartName}
          />
        );
      case ACCESSORIES:
        return <AccessoryTab data={data} />;
      default:
        <></>;
    }
  };

  return (
    <>
      <StyledTabContainer>
        <TabButtonGroup>
          {hasMCPackages && (
            <TabButton
              active={currentTab === PACKAGE}
              onClick={() => setCurrentTab(PACKAGE)}
            >
              {packageLabel}
            </TabButton>
          )}
          <TabButton
            active={currentTab === COLOR}
            onClick={() => setCurrentTab(COLOR)}
          >
            {colorLabel}
          </TabButton>
          {hasMCAccessories && (
            <TabButton
              active={currentTab === ACCESSORIES}
              onClick={() => setCurrentTab(ACCESSORIES)}
            >
              {accessoriesLabel}
            </TabButton>
          )}
        </TabButtonGroup>
        <StyledTabContent>{renderTabContent()}</StyledTabContent>
      </StyledTabContainer>
    </>
  );
}

const StyledTabContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$backgroundPrimary',
  '@mediaMinLarge': {
    px: 0,
  },
});

const StyledTabContent = styled('div', {
  color: '$secondary2',
  py: 4,
  overflowY: 'auto',
  height: `calc(100vh - ${calcHeightToBarMobile}px - ${SizeConstants.configuratorImageHeightSmall}px - ${tabButtonGroupHeight}px)`,
  '@mediaMinMedium': {
    height: `calc(100vh - ${calcHeightToBarMobile}px - ${SizeConstants.configuratorImageHeightMedium}px - ${tabButtonGroupHeight}px)`,
  },
  '@mediaMinLarge': {
    height: `calc(100vh - ${calcHeightToBarDesktop}px - ${tabButtonGroupHeight}px)`,
  },
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

export default ConfigurationArea;
