import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import {
  GetMotorcycleConfiguratorCart,
  RemoveFromMotorcycleConfiguratorCart,
  UpdateMotorcycleConfiguratorCart,
} from 'Shared/MotorcycleConfiguratorCart/MotorcycleConfiguratorCart';
import Button from 'Atoms/Buttons/Button';
import Modal from 'Organisms/Modal/Modal';
import OptionItem from './OptionItem';
import { useVariantData } from 'Shared/Providers/MCCVariantProvider';
import { CartError } from 'Organisms/FavoriteItem/FavoriteItem';
import AddToCartError from 'Molecules/AddCartError/AddCartError';
import { SMALL_CARD_PRESET_DESKTOP } from '../Tab/AccessoryTab';
import VariationModel from 'Models/KexVariation/VariationModel.interface';

type MyOptionsTypes = {
  productPricing: JSX.Element;
  productAttributes: JSX.Element;
  onShowSummaryModal: () => void;
  showSummaryModal: boolean;
  onOrder: () => void;
  cartError: CartError;
  setAddToCartError: (arg: CartError) => void;
};

const MyOptions = ({
  productPricing,
  productAttributes,
  showSummaryModal,
  onShowSummaryModal,
  onOrder,
  cartError,
  setAddToCartError,
}: MyOptionsTypes) => {
  const { displayName, cartName } =
    useCurrentPage<MotorcycleConfiguratorPageModel>();
  const { productLabels } = useTranslationData();
  const { languageRoute } = useAppSettingsData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const { state } = useVariantData();

  const configuratorCart = GetMotorcycleConfiguratorCart(
    languageRoute,
    cartName
  );

  function handleIncrement(item: VariationModel) {
    UpdateMotorcycleConfiguratorCart(item.code, 1, languageRoute, cartName);
  }

  function handleDecrement(item: VariationModel) {
    UpdateMotorcycleConfiguratorCart(item.code, -1, languageRoute, cartName);
  }

  function handleRemove(item: VariationModel) {
    RemoveFromMotorcycleConfiguratorCart(item.code, languageRoute, cartName);
  }

  if (!configuratorCart.cart.lineItems) return <></>;

  return (
    <Modal showModal={showSummaryModal} toggle={onShowSummaryModal} noPadding>
      <MyOptionsContainer>
        <OptionsContent>
          {isDesktop ? (
            <ModalTitle>{displayName}</ModalTitle>
          ) : (
            productAttributes
          )}
          <OptionsHeading>{productLabels.options}</OptionsHeading>
        </OptionsContent>
        <OptionsList>
          {configuratorCart.cart.lineItems
            .filter((item) => !item.isMotorcycle)
            .map((item) => {
              const uniqueIdx = item.code + item.isGift;

              return (
                <OptionItem
                  key={uniqueIdx}
                  product={item}
                  preset={SMALL_CARD_PRESET_DESKTOP}
                  onRemove={() => handleRemove(item)}
                  onIncrement={() => handleIncrement(item)}
                  onDecrement={() => handleDecrement(item)}
                  isDesktop={isDesktop}
                />
              );
            })}
        </OptionsList>
      </MyOptionsContainer>

      <OptionsPrice>
        {productPricing}
        {cartError.message !== '' && (
          <AddToCartError
            message={cartError.message}
            setAddToCartError={setAddToCartError}
            offset={{ top: -72, left: 0 }}
          />
        )}

        <Button
          onClick={onOrder}
          css={ButtonWidth}
          disabled={!state.selectedVariant?.isBuyable}
        >
          {productLabels.order}
        </Button>
      </OptionsPrice>
    </Modal>
  );
};
const MyOptionsContainer = styled('div', {
  height: '80vh',
  paddingTop: '24px',
  paddingBottom: '24px',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

const OptionsContent = styled('div', {
  color: '$secondary2',
  pt: 8,
  px: 4,
  '@mediaMinLarge': {
    pt: 20,
    px: 6,
  },
});

const OptionsPrice = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  bottom: 0,
  left: 0,
  position: 'absolute',
  px: 4,
  pt: 6,
  pb: 10,
  width: '100%',
  backgroundColor: '$primary4',
  gap: 16,
});

const OptionsHeading = styled('span', {
  fontFamily: '$fontPrimary400',
  ls: '$ls1',
  fs: 10,
  lineHeight: '$lh12',
  wordSpacing: '$wordSpacings$fontPrimary',
  mb: 1,
  textTransform: 'uppercase',
  display: 'block',
  mt: 8,
});

const OptionsList = styled('div', {
  px: 6,
});

export const ModalTitle = styled('span', {
  color: '$secondary2',
  display: 'block',
  fs: 10,
  lineHeight: '$lh12',
  fontFamily: '$fontSecondary600',
  '@mediaMinLarge': {
    fs: 16,
    lineHeight: '$lh1125',
  },
});

const ButtonWidth = {
  flexGrow: 1,
  '@mediaMinLarge': {
    minWidth: 200,
    flexGrow: 0,
  },
};

export default MyOptions;
