import { styled } from 'stitches.config';
import useMedia from 'Shared/Hooks/useMedia';

import { isEmpty } from 'Shared/Common/Helpers';

import Price from 'Atoms/Price/Price';
import ProductImage from 'Atoms/Image/ProductImage';
import Modal from 'Organisms/Modal/Modal';
import Button from 'Atoms/Buttons/Button';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import { ModalTitle } from '../Order/MyOptions';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';

import VariationModel from 'Models/KexVariation/VariationModel.interface';
import ProductTranslations from 'Models/Translations/ProductTranslations.interface';
import ProductBaseModel from 'Models/Pages/Base/ProductBaseModel.interface';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import Attributes from 'Molecules/Attributes/Attributes';
import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import { useMemo } from 'react';
import ImageModel from 'Models/Assets/ImageModel.interface';

type AccessoryModalTypes = {
  showModal: boolean;
  setShowModal: (set: boolean) => void;
  product?: ProductBaseModel;
  variant?: VariationModel;
  productLabels: ProductTranslations;
  onAddItem: (code: string) => void;
  placeholderImage: ImageModel;
};

const AccessoryModal = ({
  product,
  variant,
  showModal,
  setShowModal,
  productLabels,
  onAddItem,
  placeholderImage,
}: AccessoryModalTypes) => {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const hasTechnicalData =
    !isEmpty(variant?.color.key) ||
    !isEmpty(product?.material) ||
    !isEmpty(product?.certifications);

  const memoizedUspList = useMemo(() => {
    return product?.usp.slice(0, 3);
  }, [product]);

  return (
    <Modal showModal={showModal} toggle={() => setShowModal(!showModal)}>
      {!variant || !product ? (
        <></>
      ) : (
        <AccessoryModalContent>
          <ModalImage>
            <ProductImage
              src={
                variant.variationImages[0]?.src
                  ? variant.variationImages[0].src
                  : placeholderImage.src
              }
              alt=""
            />
          </ModalImage>
          <Attributes
            attributeList={[
              { value: product.parentCategoryName },
              { value: product.brand },
            ]}
          />
          <ModalTitle>{variant.name}</ModalTitle>
          <VariantUSP>
            {!isEmpty(product.usp) &&
              memoizedUspList?.map((text: string, index: number) => (
                <BulletPoint
                  key={text + index}
                  text={text}
                  backgroundColor="secondaryDark"
                />
              ))}
          </VariantUSP>

          {hasTechnicalData && (
            <>
              <CollapsableSection
                title={productLabels.technicalDetails}
                fullWidth
              >
                <Technical>
                  <>
                    {!isEmpty(variant.color.key) && (
                      <TechnicalItem>
                        <span> {productLabels.color}</span>
                        <span> {variant.color.name}</span>
                      </TechnicalItem>
                    )}
                    {!isEmpty(product.material) && (
                      <TechnicalItem>
                        <span> {productLabels.material}</span>
                        <span> {product.material}</span>
                      </TechnicalItem>
                    )}
                    {!isEmpty(product.certifications) && (
                      <TechnicalItem>
                        <span> {productLabels.certifications}</span>
                        <span> {product.certifications.join(',')}</span>
                      </TechnicalItem>
                    )}
                  </>
                </Technical>
              </CollapsableSection>
            </>
          )}

          {product.extendedDescription && (
            <>
              <CollapsableSection
                title={productLabels.detailedDescription}
                fullWidth
              >
                <XHtmlArea content={product.extendedDescription} />
              </CollapsableSection>
            </>
          )}

          <VariantPrice>
            <div>
              <Price
                friendlyWasPrice={variant.wasPrice?.priceRoundedAsString}
                friendlyNowPrice={variant.nowPrice?.priceRoundedAsString}
                wasPrice={variant.wasPrice?.price}
                nowPrice={variant.nowPrice?.price}
                size={isDesktop ? 'lg' : 'sm'}
              />
              {/* <MonthlyPrice>
                <PricePerMonth
                  friendlyMonthlyPrice={
                    variant.paymentMonthlyCost?.priceRoundedAsString
                  }
                />
              </MonthlyPrice> */}
            </div>
            <Button onClick={() => onAddItem(variant.code)}>
              {productLabels.add}
            </Button>
          </VariantPrice>
        </AccessoryModalContent>
      )}
    </Modal>
  );
};

const AccessoryModalContent = styled('div', {
  height: '70vh',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
  '@mediaMinExtraSmall': {
    height: '80vh',
  },
});

const Technical = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const TechnicalItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  fs: 8,
  lineHeight: '$lh125',
  mb: 2,
  '&:last-child': {
    mb: 0,
  },
});

const ModalImage = styled('div', {
  mt: 16,
  mb: 4,
  maxHeight: 247,
  aspectRatio: '16/9',
});

const VariantPrice = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
  backgroundColor: '$primary4',
  bottom: 0,
  left: 0,
  right: 0,
  px: 4,
  py: 6,
  position: 'absolute',
  '@mediaMinLarge': {
    py: 8,
  },
});

const VariantUSP = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  mb: 8,
  mt: 4,
});

export default AccessoryModal;
