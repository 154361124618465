import VariationModel from '../../../Models/KexVariation/VariationModel.interface';
import { Variant } from '../../../Enums/Variant.enum';

export type VariantStateType = {
  selectedVariant: VariationModel | null;
  hasColors: boolean;
  hasSizes: boolean;
  hasSelectedSize: boolean;
  isAddCartError: boolean;
};

type VariantActionType = {
  selectedVariant: VariationModel | null;
  hasColors: boolean;
  hasSizes: boolean;
  hasSelectedSize: boolean;
  isAddCartError: boolean;
  type: Variant.SET_INITIAL | Variant.SET_VARIANT | Variant.SET_ADD_CART_ERROR;
};

export type VariantReducerActionType = {
  type: Variant.SET_INITIAL | Variant.SET_VARIANT | Variant.SET_ADD_CART_ERROR;
} & Partial<VariantActionType>;

const MCCVariantReducer = (
  state: VariantStateType,
  action: VariantActionType
): VariantStateType => {
  switch (action.type) {
    case Variant.SET_INITIAL: {
      return {
        ...state,
        selectedVariant: action.selectedVariant,
        hasColors: action.hasColors,
        hasSizes: action.hasSizes,
      };
    }
    case Variant.SET_VARIANT: {
      return {
        ...state,
        selectedVariant: action.selectedVariant,
        hasSelectedSize: action.hasSelectedSize,
        isAddCartError: false,
      };
    }
    case Variant.SET_ADD_CART_ERROR: {
      return {
        ...state,
        isAddCartError: action.isAddCartError,
      };
    }
    default:
      console.warn(`unknown type ${action.type}`);
      return state;
  }
};

export default MCCVariantReducer;
