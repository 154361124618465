import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { canUseDOM } from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import Button from 'Atoms/Buttons/Button';
import { H4, H2 } from 'Atoms/Typography/Headings/Heading';
import Modal from 'Organisms/Modal/Modal';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import Input from 'Atoms/Input/Input';
import { InputValidation } from 'Atoms/Input/InputValidation';

type ShareTypes = {
  showModal: boolean;
  onShowModal: () => void;
};

const Share = ({ showModal, onShowModal }: ShareTypes) => {
  const { cartId, displayName } =
    useCurrentPage<MotorcycleConfiguratorPageModel>();
  const { productLabels } = useTranslationData();
  const { staticPages } = useAppSettingsData();

  if (!canUseDOM()) return <></>;
  const configuratorPage = staticPages.motorcycleConfiguratorPage.slice(0, -1);
  const shareUrl = `${window.location.origin}${configuratorPage}?configuration=${cartId}`;

  return (
    <>
      <Modal showModal={showModal} toggle={onShowModal} noPadding>
        <ModalContent>
          <H2 css={{ mb: 12 }}>{displayName}</H2>
          <H4 css={{ mb: 4 }}>{productLabels.shareYourMotorcycle}</H4>
          <ShareDescription>
            <Paragraph>
              {productLabels.shareYourMotorcycleDescription}
            </Paragraph>
          </ShareDescription>
          <InputValidation>
            <ShareInput>
              <Input title="URL" defaultValue={shareUrl} />
            </ShareInput>
          </InputValidation>
        </ModalContent>
        <CopyUrl>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(shareUrl);
            }}
            fullWidth
          >
            {productLabels.copyLink}
          </Button>
        </CopyUrl>
      </Modal>
    </>
  );
};

const ShareInput = styled('div', { position: 'relative' });

const ShareDescription = styled('div', {
  mb: 8,
});

const ModalContent = styled('div', {
  pb: 8,
  pt: 16,
  px: 4,
  '@mediaMinLarge': {
    px: 6,
  },
});

const CopyUrl = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
  backgroundColor: '$primary4',
  bottom: 0,
  left: 0,
  right: 0,
  px: 4,
  pt: 6,
  pb: 10,
  position: 'absolute',
  '@mediaMinLarge': {
    px: 6,
    py: 8,
  },
});

export default Share;
