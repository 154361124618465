import { useState } from 'react';
import { styled } from 'stitches.config';
import Carousel from '../../../Organisms/Carousel/Carousel';
import FullScreenModal from '../../../Organisms/Modal/FullScreenModal';
import { useVariantData } from 'Shared/Providers/MCCVariantProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import BackButton from './BackButton';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import { calcHeightToBarDesktop } from '../ConfigurationArea';
import { SizeConstants } from 'Theme/Settings/sizes';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import BlurredImage from 'Pages/ProductPage/BlurredImage';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

function ProductImage() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const { missingImage } = useAppSettingsData();

  const { isUsed, productUrl } =
    useCurrentPage<MotorcycleConfiguratorPageModel>();

  const { state } = useVariantData();
  const kexNavigate = useKexNavigate();

  if (!state?.selectedVariant?.variationImages) return <></>;

  const variationImages = state.selectedVariant?.variationImages;

  const handleCurrentImage = (current: number) => {
    setCurrentImage(current);
  };

  const returnAction = () => {
    kexNavigate(productUrl);
  };

  const handleFullScreen = (index: number) => {
    setIsFullScreen(!isFullScreen);
    handleCurrentImage(index);
  };

  const renderFullScreenCarousel = () => {
    return (
      isFullScreen && (
        <FullScreenModal
          showModal={isFullScreen}
          close={() => setIsFullScreen(false)}
        >
          <Carousel
            images={variationImages}
            handleFullScreen={handleFullScreen}
            isFullScreen
            hasCounter
            hasThumbnail={false}
            currentImage={currentImage}
            handleCurrentImage={handleCurrentImage}
            isUsed={isUsed}
          />
        </FullScreenModal>
      )
    );
  };

  if (!variationImages || variationImages.length === 0) {
    const missingSrc = `${missingImage.src}?preset=product-card-new-desktop`;
    return (
      <BlurredImage
        key={0}
        index={0}
        lowQualitySrc={missingSrc}
        highQualitySrc={missingSrc}
        handleFullScreen={() => {}}
        isFullWidth={true}
        isUsed={false}
      />
    );
  }

  return (
    <CarouselContainer isFullScreen={isFullScreen}>
      <ButtonWrapper>
        <BackButton size="l" bgColor="secondary" onClick={returnAction} />
      </ButtonWrapper>
      <Carousel
        images={variationImages}
        handleFullScreen={handleFullScreen}
        currentImage={currentImage}
        hasThumbnail={false}
        hasCounter={false}
        handleCurrentImage={handleCurrentImage}
        isUsed={isUsed}
        isMCC={true}
      />
      {renderFullScreenCarousel()}
    </CarouselContainer>
  );
}

export const configuratorImageHeight = {
  small: 280,
  medium: 480,
};

const CarouselContainer = styled('div', {
  position: 'relative',
  h: '100%',
  variants: {
    isFullScreen: {
      false: {
        img: { height: SizeConstants.configuratorImageHeightSmall },
        '@mediaMinMedium': {
          img: { height: SizeConstants.configuratorImageHeightMedium },
        },
        '@mediaMinLarge': {
          img: {
            height: `calc(100vh - ${calcHeightToBarDesktop}px)`,
          },
        },
      },
    },
  },
});

const ButtonWrapper = styled('div', {
  position: 'absolute',
  zIndex: 1,
  t: 4,
  l: 4,
});

export default ProductImage;
