import { RemoveIcon } from 'Atoms/Icons';
import QuantitySelector from 'Atoms/Quantity/QuantitySelector';
import KexLink from 'Kex/KexLink';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Price from 'Atoms/Price/Price';
import { MIN_QUANTITY } from 'Shared/Common/QuantityLimit';
import ProductImage from 'Atoms/Image/ProductImage';
import { styled } from 'stitches.config';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';
import useProductImage from 'Shared/Hooks/useProductImage';

type PropTypes = {
  product: VariationModel;
  preset: string;
  onRemove: () => void;
  onIncrement: () => void;
  onDecrement: () => void;
  isDesktop: boolean;
  size?: 's' | 'm';
  customOnClick?: () => void;
};

function OptionItem({
  product,
  preset,
  onRemove,
  onIncrement,
  onDecrement,
  isDesktop,
  size = 'm',
  customOnClick,
}: PropTypes) {
  const {
    to,
    image,
    name,
    code,
    totalNowPrice,
    totalWasPrice,
    wasPrice,
    nowPrice,
    isUsed,
    quantity,
    stock,
    variationImages,
    isGift,
  } = product;

  const { itemImage } = useProductImage(image, variationImages);

  return (
    <OptionItemContainer>
      <OptionItemTop>
        <KexLink
          css={{ mb: 0 }}
          href={customOnClick ? undefined : to}
          underlined={false}
        >
          <ImageContainer size={size} onClick={customOnClick}>
            <ProductImage
              src={`${itemImage.src}${preset}`}
              alt={itemImage.alt}
              isUsed={isUsed}
            />
          </ImageContainer>
        </KexLink>
        <OptionItemContent>
          <OptionItemRow>
            <StyledKexLink
              href={customOnClick ? undefined : to}
              underlined={false}
            >
              <ProductCardTitle
                title={name}
                size={size}
                onClick={customOnClick}
              />
            </StyledKexLink>
            {isDesktop && !isGift && (
              <RemoveButton onClick={onRemove}>
                <RemoveIcon size="s" color="secondary" />
              </RemoveButton>
            )}
          </OptionItemRow>
          <OptionItemRow>
            <div>
              <Price
                friendlyWasPrice={totalWasPrice?.priceRoundedAsString}
                friendlyNowPrice={totalNowPrice?.priceRoundedAsString}
                wasPrice={wasPrice?.price}
                nowPrice={nowPrice?.price}
                direction="columnReverse"
                nowPriceSize={{ fs: size === 's' ? 6 : 8 }}
                wasPriceSize={{ fs: 6 }}
              />
            </div>
            {isDesktop && (
              <QuantitySelector
                code={code}
                maxQuantity={stock.stockQuantity}
                minQuantity={MIN_QUANTITY}
                currentQuantity={quantity}
                handleIncrement={onIncrement}
                handleDecrement={onDecrement}
                disabledMin={isGift}
              />
            )}
          </OptionItemRow>
        </OptionItemContent>
      </OptionItemTop>
      {!isDesktop && (
        <OptionItemBottom>
          {!isGift ? (
            <RemoveButton onClick={() => onRemove()} size={size}>
              <RemoveIcon size="s" color="secondary" />
            </RemoveButton>
          ) : (
            <div></div> // placeholder
          )}

          <QuantitySelector
            code={code}
            maxQuantity={stock.stockQuantity}
            minQuantity={MIN_QUANTITY}
            currentQuantity={quantity}
            handleIncrement={onIncrement}
            handleDecrement={onDecrement}
            disabledMin={isGift}
          />
        </OptionItemBottom>
      )}
    </OptionItemContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',
  '@mediaMinLarge': {
    maxW: '80%',
  },
});

const OptionItemContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  flexWrap: 'wrap',
  '&:not(:last-child)': {
    py: 5,
    borderBottomWidth: '1px',
    borderBottomColor: '$checkoutPageBackgroundPrimary',
  },
  '&:last-child': {
    pt: 5,
    mb: 6,
  },
});

const OptionItemTop = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',
  flexGrow: 1,
});

const imageSize = {
  s: {
    width: 72,
    maxHeight: 56,
  },
  m: {
    width: 104,
    maxHeight: 80,
  },
};

const ImageContainer = styled('div', {
  mr: 4,
  variants: {
    size: {
      ...imageSize,
    },
  },
});

const OptionItemRow = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

const OptionItemContent = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const OptionItemBottom = styled('div', {
  mt: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const RemoveButton = styled('button', {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    size: { ...imageSize },
  },
});

export default OptionItem;
