import CheckBox from '../../Atoms/Checkbox/Checkbox';
import { styled } from 'stitches.config';
import { useState } from 'react';

type PropType = {
  isActive: boolean;
  onClickInfo: () => void;
  onClickCheckbox: () => void;
  title: string;
  price: string;
  text: string;
};

const OptionCardCheckbox = ({
  isActive,
  onClickInfo,
  onClickCheckbox,
  title,
  price,
  text,
}: PropType) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Container
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Information onClick={onClickInfo} isHovered={isHovered}>
        <TopInformation>
          <Title isHovered={isHovered}>{title}</Title>
          <Price isHovered={isHovered}>+ {price}</Price>
        </TopInformation>
        <BottomInformation>
          <Description>{text}</Description>
        </BottomInformation>
      </Information>
      <CheckBoxWrapper isHovered={isHovered} onClick={onClickCheckbox}>
        <CheckBox
          css={{ pointerEvents: 'none' }}
          id="OptionCardCheckbox"
          isChecked={isActive}
          text={''}
          onCheck={() => {}}
          noMargin
        />
      </CheckBoxWrapper>
    </Container>
  );
};

const Container = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  width: '100%',
  height: '134px',
  borderWidth: '1px',
  borderColor: 'transparent',
});

const Information = styled('div', {
  width: '80%',
  backgroundColor: '$primary4',
  paddingLeft: '16px',
  paddingTop: '16px',
  variants: {
    isHovered: {
      true: {
        backgroundColor: '$primary3',
      },
    },
  },
});

const TopInformation = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

const Title = styled('div', {
  fontFamily: '$fontSecondary600',
  fs: 7,
  lineHeight: '$lh125',
  height: '38px',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  '@mediaMinLarge': {
    fs: 10,
    lineHeight: '$lh12',
    letterSpacing: 0,
    height: '48px',
  },
  variants: {
    isHovered: {
      true: {
        textDecoration: 'underline',
      },
    },
  },
});

const Price = styled('div', {
  backgroundColor: '$primary6',
  whiteSpace: 'nowrap',
  mx: 4,
  px: 2,
  height: '80%',
  variants: {
    isHovered: {
      true: {
        backgroundColor: '$primary5',
      },
    },
  },
});

const BottomInformation = styled('div', {
  paddingTop: '8px',
  width: '90%',
});

const Description = styled('div', {
  fontFamily: '$fontSecondary400',
  fs: 6,
  lineHeight: '$lh133',
  letterSpacing: 0,
  textAlign: 'left',
  height: '48px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
});

const CheckBoxWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20%',
  backgroundColor: '$primary3',
  variants: {
    isHovered: {
      true: {
        backgroundColor: '$primary2',
      },
    },
  },
});

export default OptionCardCheckbox;
