import { styled } from 'stitches.config';
import InfoIcon from 'Atoms/Icons/InfoIcon';
import { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../../Features/Shared/Hooks/useOutsideClick';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

type PropTypes = { text: string };

const Tooltip = ({ text }: PropTypes) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<number>(0);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const textbubbleRef = useRef<HTMLDivElement>(null);
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  useOutsideClick(tooltipRef, () => setOpenTooltip(false));

  const toggleTooltip = () => setOpenTooltip(!openTooltip);

  useEffect(() => {
    if (tooltipRef.current && textbubbleRef.current) {
      const getTopPosition =
        tooltipRef.current.offsetTop - textbubbleRef.current.clientHeight - 8;

      setTooltipPosition(getTopPosition);
    }
  }, [openTooltip]);

  return (
    <Wrapper>
      <IconWrapper
        ref={tooltipRef}
        {...(!isMobile && {
          onMouseEnter: () => setOpenTooltip(true),
        })}
        {...(!isMobile && {
          onMouseLeave: () => setOpenTooltip(false),
        })}
        onClick={toggleTooltip}
      >
        <InfoIcon color="secondary" />
      </IconWrapper>

      <TextBubble
        ref={textbubbleRef}
        isTooltip={openTooltip}
        style={{
          top: tooltipPosition,
        }}
      >
        {text}
      </TextBubble>
    </Wrapper>
  );
};
const Wrapper = styled('div', { display: 'inline-block' });

const TextBubble = styled('div', {
  borderWidth: '1px',
  width: '100%',
  maxW: 55,
  position: 'absolute',
  zIndex: '$Tooltip',
  backgroundColor: '$backgroundPrimary',
  borderColor: '$tooltipBorder',
  p: 2,
  fs: 6,
  fontFamily: '$fontSecondary400',
  lineHeight: '$lh133',
  color: '$secondary2',
  variants: { isTooltip: { false: { opacity: 0, visibility: 'hidden' } } },
});

const IconWrapper = styled('div', {
  cursor: 'pointer',
  px: 2,
});
export default Tooltip;
