import { H3, H5 } from 'Atoms/Typography/Headings/Heading';
import { useVariantData } from '../../../Shared/Providers/MCCVariantProvider';
import { useRef, useState } from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useMedia from 'Shared/Hooks/useMedia';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

import { styled } from 'stitches.config';

import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import {
  GetMotorcycleConfiguratorCart,
  MergeMotorcycleConfiguratorCartIntoDefaultCart,
} from 'Shared/MotorcycleConfiguratorCart/MotorcycleConfiguratorCart';
import {
  AddFavoriteConfigurator,
  GetFavorites,
  RemoveFavoriteConfigurator,
} from 'Shared/Favorites/Favorites';
import FavoriteButton from 'Atoms/Buttons/FavoriteButton';
import Button from 'Atoms/Buttons/Button';
import Price from 'Atoms/Price/Price';

import ShareButton from 'Atoms/Buttons/ShareButton';
import { ChevronIcon } from 'Atoms/Icons';
import MyOptions from './MyOptions';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';

import Share from '../ConfiguratorModal/Share';
import ContactModal from 'Organisms/ContactModal/ContactModal';
import TestDriveModal from 'Organisms/TestDriveModal/TestDriveModal';
import { GetCart } from 'Shared/Cart/Cart';
import { CartError } from 'Organisms/FavoriteItem/FavoriteItem';
import AddToCartError from 'Molecules/AddCartError/AddCartError';
import SubmitInterestModal from 'Organisms/SubmitInterestModal/SubmitInterestModal';

const CtaArea = () => {
  const [showSummaryModal, setShowSummaryModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showTestDriveModal, setShowTestDriveModal] = useState<boolean>(false);
  const [showSubmitInterestModal, setSubmitInterestModal] =
    useState<boolean>(false);
  const [cartError, setAddToCartError] = useState<CartError>({ message: '' });
  const containerRef = useRef<HTMLDivElement>(null);
  const { displayName, cartName } =
    useCurrentPage<MotorcycleConfiguratorPageModel>();
  const { productLabels } = useTranslationData();
  const { languageRoute, staticPages } = useAppSettingsData();
  const {
    user: { authenticated },
    accountDispatch,
  } = useUserStateData();
  const { state } = useVariantData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const kexNavigate = useKexNavigate();

  const { favoriteList } = GetFavorites(languageRoute);
  const { hasMotorCycle } = GetCart(languageRoute);
  const CartMCC = GetMotorcycleConfiguratorCart(languageRoute, cartName);

  const onOrder = async () => {
    if (hasMotorCycle) {
      setAddToCartError({
        message: productLabels.motorcycleAlreadyInCartError,
      });
      return;
    }

    await MergeMotorcycleConfiguratorCartIntoDefaultCart(
      cartName,
      languageRoute
    );

    kexNavigate(staticPages.checkoutPage);
  };

  const isFavorited = (): boolean => {
    return favoriteList?.favoriteMotorcycleConfigurations?.some(
      (x) => x.motorcycleConfigurationCart.name === cartName
    );
  };

  const onFavoriteClick = () => {
    if (authenticated) {
      isFavorited()
        ? RemoveFavoriteConfigurator(cartName, languageRoute)
        : AddFavoriteConfigurator(cartName, languageRoute);
      return;
    }
    accountDispatch({ type: 'toggle' });
  };

  const optionsLength = CartMCC.cart?.lineItems?.length - 1;

  const isSoonInStock = state.selectedVariant?.stock?.salesStartFormatted;

  const ProductAttributes = (
    <>
      <TitleContainer>
        <H3 css={{ m: 0 }}>{displayName}</H3>
        <ShareFavoriteContainer>
          <FavoriteButton
            size={isDesktop ? 'm' : 's'}
            css={{ pr: 4 }}
            isActive={isFavorited()}
            onClick={() => {
              onFavoriteClick();
              setShowSummaryModal(false);
            }}
          />
          <ShareButton
            size={isDesktop ? 'm' : 's'}
            onClick={() => {
              setShowShareModal(true);
              setShowSummaryModal(false);
            }}
          />
        </ShareFavoriteContainer>
      </TitleContainer>
      <ContactContainer>
        {isSoonInStock ? (
          <Button
            type="quaternary"
            size={isDesktop ? 'l' : 's'}
            css={ButtonWidth}
            onClick={() => {
              setShowSummaryModal(false);
              setSubmitInterestModal(true);
            }}
          >
            {productLabels.submitOfInterest}
          </Button>
        ) : (
          <Button
            type="quaternary"
            size={isDesktop ? 'l' : 's'}
            css={ButtonWidth}
            onClick={() => {
              setShowSummaryModal(false);
              setShowTestDriveModal(true);
            }}
          >
            {productLabels.testDrive}
          </Button>
        )}

        <Button
          type="quaternary"
          size={isDesktop ? 'l' : 's'}
          css={ButtonWidth}
          onClick={() => {
            setShowSummaryModal(false);
            setShowContactModal(true);
          }}
        >
          {productLabels.contactUs}
        </Button>

        {isSoonInStock ? (
          <SubmitInterestModal
            showModal={showSubmitInterestModal}
            setShowModal={setSubmitInterestModal}
          />
        ) : (
          <TestDriveModal
            showModal={showTestDriveModal}
            setShowModal={setShowTestDriveModal}
          />
        )}

        <ContactModal
          showModal={showContactModal}
          setShowModal={setShowContactModal}
        />
      </ContactContainer>
    </>
  );

  const ProductPricing = (
    <PriceContainer>
      <Price
        friendlyWasPrice={'0'}
        friendlyNowPrice={
          CartMCC?.cart?.totalPriceForItems?.priceRoundedAsString
        }
        wasPrice={0}
        nowPrice={0}
        size={isDesktop ? 'lg' : 'sm'}
      />
      {/* <MonthlyPrice>
        <PricePerMonth
          friendlyMonthlyPrice={CartMCC.cart.monthlyCost?.priceRoundedAsString}
        />
        <Tooltip text={paymentInfoBody} />
      </MonthlyPrice> */}
    </PriceContainer>
  );

  return (
    <>
      {isDesktop ? (
        <>
          <Placeholder />
          <CtaAreaContainer>
            <CtaAreaWrapper>
              <Column>{ProductAttributes}</Column>
              <Column>
                {ProductPricing}
                <OrderContainer ref={containerRef}>
                  <Button
                    type="quaternary"
                    css={ButtonWidth}
                    onClick={() => setShowSummaryModal(true)}
                  >
                    {productLabels.options}{' '}
                    {optionsLength > 0 ? `(${optionsLength})` : ''}
                  </Button>

                  {cartError.message !== '' && (
                    <AddToCartError
                      message={cartError.message}
                      setAddToCartError={setAddToCartError}
                      offset={{ top: 0 }}
                    />
                  )}
                  <Button
                    css={ButtonWidth}
                    onClick={onOrder}
                    disabled={!state.selectedVariant?.isBuyable}
                  >
                    {productLabels.order}
                  </Button>
                </OrderContainer>
              </Column>
            </CtaAreaWrapper>
          </CtaAreaContainer>
        </>
      ) : (
        <>
          <Summary>
            <div>
              <H5 css={{ mb: 2 }}>{displayName}</H5>
              <PriceContainer>
                <Price
                  friendlyWasPrice={'0'}
                  friendlyNowPrice={
                    CartMCC?.cart?.totalPriceForItems?.priceAsString
                  }
                  wasPrice={0}
                  nowPrice={0}
                  size="xs"
                />
              </PriceContainer>
            </div>
            <CircleBackground onClick={() => setShowSummaryModal(true)}>
              <ChevronIcon
                color="secondary"
                size="m"
                css={{
                  transform: showSummaryModal
                    ? 'rotate(360deg)'
                    : 'rotate(180deg)',
                }}
              />
            </CircleBackground>
          </Summary>
        </>
      )}
      <Share
        showModal={showShareModal}
        onShowModal={() => setShowShareModal(!showShareModal)}
      />
      <MyOptions
        productAttributes={ProductAttributes}
        productPricing={ProductPricing}
        showSummaryModal={showSummaryModal}
        onShowSummaryModal={() => setShowSummaryModal(!showSummaryModal)}
        onOrder={onOrder}
        cartError={cartError}
        setAddToCartError={setAddToCartError}
      />
    </>
  );
};

const Placeholder = styled('div', {});

const CtaAreaContainer = styled('div', {
  backgroundColor: '$primary4',
  p: 8,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: '$zIndices$ConfiguratorSummary',
});

const CtaAreaWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 32,
  position: 'relative',
});

const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  mb: 4,
  '@mediaMinLarge': {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 32,
    mb: 8,
  },
});

const ShareFavoriteContainer = styled('div', {
  mt: 2,
  '@mediaMinLarge': {
    mt: 0,
  },
});

const ContactContainer = styled('div', {
  display: 'flex',
  gap: 16,
});

// const MonthlyPrice = styled('div', {
//   mt: 2,
//   fs: 6,
//   display: 'flex',
//   gap: 8,
//   lineHeight: '$lh125',
//   fontFamily: '$fontSecondary400',
//   color: '$productDescriptionTextPrimary',
// });

const PriceContainer = styled('div', {
  marginBottom: 'auto',
  mt: 2,
  flexGrow: 1,
  fontFamily: '$fontPrimary400',
  '@mediaMinLarge': {
    flexGrow: 0,
  },
});

const OrderContainer = styled('div', {
  display: 'flex',
  gap: 16,
  mt: 6,
});

const Summary = styled('div', {
  backgroundColor: '$primary6',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: '$zIndices$ConfiguratorSummary',
  p: 4,
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: '1px solid $colors$primary3',
});

const CircleBackground = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  h: 12,
  w: 12,
  backgroundColor: '$primary4',
});

const ButtonWidth = {
  flexGrow: 1,
  '@mediaMinLarge': {
    minWidth: 200,
    flexGrow: 0,
  },
};

export default CtaArea;
