import MCCProductImage from './Image/MCCProductImage';
import { calcHeightToBarDesktop } from './ConfigurationArea';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { styled } from 'stitches.config';
import { MCCVariantProvider } from '../../Shared/Providers/MCCVariantProvider';
import SelectionArea from './ConfigurationArea';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import CtaArea from './Order/CtaArea';

function MotorcycleConfiguratorPage() {
  const data = useCurrentPage<MotorcycleConfiguratorPageModel>();

  return (
    <MCCVariantProvider>
      <Top>
        <ProductImageWrapper>
          <MCCProductImage />
        </ProductImageWrapper>
        <LowerContainer>
          <SelectionArea data={data} />
        </LowerContainer>
      </Top>
      <CtaArea />
    </MCCVariantProvider>
  );
}

const Top = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  g: 0,
  w: '100%',
  '@mediaMinLarge': {
    gridTemplateColumns: '2fr 1fr',
    g: 8,
    height: `calc(100vh - ${calcHeightToBarDesktop}px)`,
    overflow: 'hidden',
  },
  '@mediaMinMassive': {
    gridTemplateColumns: '1.6fr 1fr',
  },
});

const LowerContainer = styled('div', {
  p: 4,
  '@mediaMinLarge': {
    pt: 8,
    pr: 8,
    pl: 0,
  },
});

const ProductImageWrapper = styled('div', {
  px: 0,
  w: '100%',
  height: '100%',
});

export default MotorcycleConfiguratorPage;
