import { styled } from 'stitches.config';
import { useState } from 'react';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import MotorcycleConfiguratorAccessoryCategory from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorAccessoryCategory.interface';
import MotorcycleConfiguratorPageModel from 'Models/Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPageModel.interface';
import {
  UpdateMotorcycleConfiguratorCart,
  GetMotorcycleConfiguratorCart,
  RemoveFromMotorcycleConfiguratorCart,
  SetQuantityInMotorcycleConfiguratorCart,
} from 'Shared/MotorcycleConfiguratorCart/MotorcycleConfiguratorCart';

import Price from 'Atoms/Price/Price';
import ProductImage from 'Atoms/Image/ProductImage';
import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import { AddIcon, CheckmarkIcon, RemoveIcon } from 'Atoms/Icons';
import AccessoryModal from '../ConfiguratorModal/Accessory';

import VariationModel from 'Models/KexVariation/VariationModel.interface';
import ProductBaseModel from 'Models/Pages/Base/ProductBaseModel.interface';
import { MIN_QUANTITY } from 'Shared/Common/QuantityLimit';
import QuantitySelector from 'Atoms/Quantity/QuantitySelector';
import CircleBackground from 'Atoms/Icons/CircleBackground';

type AccessoryTabTypes = {
  data: MotorcycleConfiguratorPageModel;
};

export const SMALL_CARD_PRESET_DESKTOP = '?preset=small-product-card-desktop';

const AccessoryTab = ({ data }: AccessoryTabTypes) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentVariant, setCurrentVariant] = useState<VariationModel>();
  const [currentProduct, setCurrentProduct] = useState<ProductBaseModel>();

  const { cartName } = useCurrentPage<MotorcycleConfiguratorPageModel>();
  const { languageRoute, missingImage } = useAppSettingsData();
  const { productLabels } = useTranslationData();

  const configuratorCart = GetMotorcycleConfiguratorCart(
    languageRoute,
    cartName
  );

  const onAddItem = (code: string) => {
    const quantity = 1;
    UpdateMotorcycleConfiguratorCart(
      code,
      quantity,
      languageRoute,
      data?.cartName
    );

    setShowModal(false);
  };

  return (
    <>
      <AccessoryModal
        showModal={showModal}
        setShowModal={setShowModal}
        variant={currentVariant}
        product={currentProduct}
        productLabels={productLabels}
        onAddItem={onAddItem}
        placeholderImage={missingImage}
      />
      {data.accessories.map(
        (category: MotorcycleConfiguratorAccessoryCategory, index) => (
          <CollapsableSection
            key={category.text + index}
            title={category.text}
            icon={category.navigationImage?.src}
            fullWidth
          >
            <AccessoryList>
              {category.products?.map((product: ProductBaseModel) => {
                return product.variationCollection.map(
                  (variant: VariationModel) => {
                    const alreadyAdded = configuratorCart.cart?.lineItems?.find(
                      (item) => item.code === variant.code
                    );

                    const uniqueIdx = variant.code + variant.isGift;

                    return (
                      <AccessoryCard key={uniqueIdx}>
                        <AccessoryCardTop
                          onClick={() => {
                            setCurrentVariant(variant);
                            setCurrentProduct(product);
                            setShowModal(true);
                          }}
                        >
                          <AccessoryCardImage>
                            <ProductImage
                              src={
                                variant.image?.src
                                  ? `${variant.image.src}${SMALL_CARD_PRESET_DESKTOP}`
                                  : variant.variationImages[0]
                                  ? `${variant.variationImages[0].src}${SMALL_CARD_PRESET_DESKTOP}`
                                  : `${missingImage.src}${SMALL_CARD_PRESET_DESKTOP}`
                              }
                              alt={variant.image?.alt}
                            />
                          </AccessoryCardImage>
                          <AccessoryCardContent>
                            <AccessoryCardTitle>
                              {variant.name}
                            </AccessoryCardTitle>
                            <AccessoryCardPrice>
                              <Price
                                friendlyWasPrice={
                                  variant.wasPrice?.priceRoundedAsString
                                }
                                friendlyNowPrice={
                                  variant.nowPrice?.priceRoundedAsString
                                }
                                wasPrice={variant.wasPrice?.price}
                                nowPrice={variant.nowPrice?.price}
                                nowPriceSize={{ fs: 6 }}
                                wasPriceSize={{ fs: 6 }}
                              />
                              {alreadyAdded ? (
                                <>
                                  <CircleBackground
                                    backgroundColorVariants="primary"
                                    large
                                    css={{ mr: 0 }}
                                  >
                                    <CheckmarkIcon
                                      color="secondaryDark"
                                      size="xs"
                                    />
                                  </CircleBackground>
                                </>
                              ) : (
                                <AddIconCircle
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    onAddItem(variant.code);
                                  }}
                                >
                                  <AddIcon color="primary" />
                                </AddIconCircle>
                              )}
                            </AccessoryCardPrice>
                          </AccessoryCardContent>
                        </AccessoryCardTop>
                        {alreadyAdded && (
                          <AccessoryCardBottom>
                            <RemoveButton
                              onClick={() =>
                                RemoveFromMotorcycleConfiguratorCart(
                                  alreadyAdded.code,
                                  languageRoute,
                                  cartName
                                )
                              }
                            >
                              <RemoveIcon size="s" color="secondary" />
                            </RemoveButton>

                            <QuantitySelector
                              code={alreadyAdded.code}
                              maxQuantity={alreadyAdded.stock.stockQuantity}
                              minQuantity={MIN_QUANTITY}
                              currentQuantity={alreadyAdded.quantity}
                              handleIncrement={() => {
                                SetQuantityInMotorcycleConfiguratorCart(
                                  alreadyAdded.code,
                                  alreadyAdded.quantity + 1,
                                  languageRoute,
                                  cartName
                                );
                              }}
                              handleDecrement={() => {
                                SetQuantityInMotorcycleConfiguratorCart(
                                  alreadyAdded.code,
                                  alreadyAdded.quantity - 1,
                                  languageRoute,
                                  cartName
                                );
                              }}
                            />
                          </AccessoryCardBottom>
                        )}
                      </AccessoryCard>
                    );
                  }
                );
              })}
            </AccessoryList>
          </CollapsableSection>
        )
      )}
    </>
  );
};

const AccessoryList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
});

const AccessoryCard = styled('div', {
  position: 'relative',
  '&:not(:last-child)': {
    py: 4,
    borderBottomWidth: '1px',
    borderBottomColor: '$primary6',
  },
  '&:first-child': {
    pt: 0,
  },
  '&:last-child:not(:only-child)': {
    pt: 4,
  },
});

const AccessoryCardTop = styled('div', {
  display: 'flex',
  cursor: 'pointer',
});

const AccessoryCardImage = styled('div', {
  width: 72,
  height: 56,
  mr: 4,
});

const AccessoryCardTitle = styled('span', {
  fontFamily: '$fontSecondary600',
  color: '$cartItemText',
  fs: 7,
});

const AccessoryCardContent = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const AccessoryCardPrice = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const AddIconCircle = styled('button', {
  borderRadius: '50%',
  border: '1px solid $JE68GreenPrimary',
  p: 1.25,
});

const AccessoryCardBottom = styled('div', {
  mt: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const RemoveButton = styled('button', {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  width: 72,
  maxHeight: 56,
});

export default AccessoryTab;
