import { ShareIcon } from 'Atoms/Icons';
import { styled } from 'stitches.config';

type ShareButtonBaseType = {
  onClick?: () => void;
  css?: any;
  size: 's' | 'm'; // 32px, 48px
  color?: 'primary' | 'secondary';
  isActive?: boolean;
};

function ShareButton({
  onClick = () => {},
  css,
  size,
  color = 'primary',
}: ShareButtonBaseType) {
  return (
    <ShareBtn
      css={css}
      onClick={() => {
        onClick();
      }}
      size={size}
    >
      <CircleBackground bgColor={color} size={size}>
        <ShareIcon color={'secondary'} fillColor={'transparent'} size={'s'} />
      </CircleBackground>
    </ShareBtn>
  );
}
export default ShareButton;

const ShareBtn = styled('button', {
  variants: {
    size: {
      s: {
        h: 8,
      },
      m: {
        h: 12,
      },
    },
  },
});

const CircleBackground = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  variants: {
    bgColor: {
      primary: {
        backgroundColor: '$primary2',
        '&:hover': {
          backgroundColor: '$primary2',
        },
      },
      secondary: {
        backgroundColor: '$pureWhite',
        '&:hover': {
          backgroundColor: '$secondary2',
        },
      },
    },
    size: {
      s: {
        h: 8,
        w: 8,
      },
      m: {
        h: 12,
        w: 12,
      },
    },
  },
});
