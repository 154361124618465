import { useReducer, useContext, createContext } from 'react';
import MCCVariantReducer, {
  VariantReducerActionType,
} from '../../Pages/MotorcycleConfiguratorPage/Reducers/MCCVariantReducer';
import { VariantStateType } from '../../Pages/ProductPage/Reducers/variantReducer';

type PropType = {
  children: React.ReactNode;
};

const VariantContext = createContext({});

export const MCCVariantProvider = ({ children }: PropType) => {
  const [state, variantDispatch] = useReducer(MCCVariantReducer, {
    selectedVariant: null,
    hasColors: false,
    hasSizes: false,
    hasSelectedSize: false,
    isAddCartError: false,
  });

  return (
    <VariantContext.Provider value={{ state, variantDispatch }}>
      {children}
    </VariantContext.Provider>
  );
};

export const useVariantData = () => {
  return useContext(VariantContext) as {
    state: VariantStateType;
    variantDispatch: (action: VariantReducerActionType) => void;
  };
};
